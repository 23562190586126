<template>
  <el-container>
    <el-header>UUID Online</el-header>

    <el-main>
      <el-row>
        <el-col :span="24" class="uuid4-style">
          {{ uuUidOne }}
          <div class="el-button-group">
            <button type="button" class="el-button el-button--primary el-button--medium" @click="generateOne">
              <i class="el-icon-refresh"></i>
              <span>Refresh</span>
            </button>
            <button type="button" class="el-button el-button--primary el-button--medium btn-copy-uuid-one"
                    @click="copyUuidOne">
              <i class="el-icon-copy-document"></i>
              <span>{{ uuUidOneCopyBtnText }}</span>
            </button>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" style="margin-top: 50px;">
          <el-form ref="versionForm" :inline="true" :rules="versionRules" :model="versionForm" label-width="180px"
                   class="form-style">
            <el-form-item label="Check UUID Version" prop="uuid">
              <el-input v-model="versionForm.uuid" placeholder="请输入" style="width: 320px"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group>
                <el-button type="primary" icon="el-icon-check" @click="submitCheckForm">Check</el-button>
              </el-button-group>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>


      <el-row class="uuidVersion">
        <el-col :span="24" style="margin-top: 50px;">
          <el-divider direction="horizontal">
            <el-radio-group v-model="batchForm.version" size="small" fill="#303133">
              <el-radio-button label="UUID1"></el-radio-button>
              <el-radio-button label="UUID3"></el-radio-button>
              <el-radio-button label="UUID4"></el-radio-button>
              <el-radio-button label="UUID5"></el-radio-button>
              <el-radio-button label="UUID6"></el-radio-button>
              <el-radio-button label="UUID7"></el-radio-button>
            </el-radio-group>

            <el-checkbox v-model="batchForm.checkbox" class="myCheckBox">Without "-"</el-checkbox>

          </el-divider>
        </el-col>
      </el-row>

      <el-form ref="batchForm" :inline="true" :rules="batchRules" :model="batchForm" label-width="180px"
               class="form-style">
        <el-form-item label="How Many?(1-3000)" prop="num">
          <el-input v-model="batchForm.num" placeholder="请输入" oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="primary" icon="el-icon-thumb" @click="submitForm">Generate</el-button>
            <el-button type="primary" icon="el-icon-copy-document" class="btn-copy-uuid-list" @click="copyUuidList">
              {{ uuUidListCopyBtnText }}
            </el-button>
          </el-button-group>
        </el-form-item>
      </el-form>

      <div>
        <div v-for="(item, index) in uuidList" :key="index">{{ item }}</div>
      </div>
    </el-main>

    <el-footer>
      <el-collapse v-model="activeNames" accordion>
        <el-collapse-item title="How to choose UUID version?" name="1">
          <div>
            UUID1 : Generate a UUID from a host ID, sequence number, and the
            current time. It based on the hardware address and a random 14-bit sequence number.
          </div>
          <div>
            UUID3 : Generate a UUID based on the MD5 hash of a namespace
            identifier (which is a UUID) and a name (which is a string,this site use UUID1 as
            string).
          </div>
          <div>UUID4 : Generate a random UUID.</div>
          <div>
            UUID5 : Generate a UUID based on the SHA-1 hash of a namespace
            identifier (which is a UUID) and a name (which is a string,this site use UUID1 as
            string).
          </div>
          <div>
            UUID6 : UUID version 6 is a field-compatible version of UUIDv1, reordered for improved DB locality.
            It is expected that UUIDv6 will primarily be used in contexts where there are existing v1 UUIDs.
            Systems that do not involve legacy UUIDv1 SHOULD use UUIDv7 instead..
          </div>
          <div>
            UUID7 : Version 7 has the nice characteristic that the start of a UUID encodes the time with a chronological
            sort order and potentially ~50ns time resolution,
            while the end of the UUID includes sufficient random bits to ensure consecutive UUIDs will remain unique.
          </div>
        </el-collapse-item>
        <el-collapse-item title="What is UUID?" name="2">
          <div>
            A universally unique identifier (UUID) is a 128-bit number that
            identifies unique Internet objects or data. A UUID is generated by an algorithm with
            values that are based on a machine's network address.
          </div>
          <div>
            UUIDs are used by many software companies, such as Microsoft and
            Apple, and are extensively used as components of Microsoft’s globally unique
            identifiers (GUIDs). Other UUID uses include Linux’s ext2/ext3 file
            system.Techopedia explains Universally Unique Identifier (UUID)
          </div>
          <div>
            The UUID was created in the Network Computing System (NCS),
            which later became a part of the Distributed Computing Environment (DCE)
            standardized by the Open Software Foundation (OSF).
          </div>
          <div>
            A UUID is typically denoted by 32 hexadecimal digits displayed
            in five character groups individually separated by hyphens.
          </div>
          <div>
            Different mechanisms are used to generate UUIDs to determine and
            compare UUID uniqueness levels. Based on the type of mechanism used, the generated
            UUID will be either completely or practically different from other generated UUIDs.
            UUIDs are made up of combined components; therefore, some kind of uniqueness is
            always present in any generated UUID.
          </div>
          <div>
            A guaranteed unique identifier includes a reference to the
            network address of the UUID generating host, a time stamp and an arbitrary
            component. Because network addresses for each computer vary, the time stamp is also
            different for each generated UUID. Thus, two different host machines exhibit
            sufficient levels of uniqueness. The randomly created arbitrary component is added
            for enhanced security.
          </div>
          <div>
            UUIDs are also part of the Tmodel data structure, which is a
            service type in the Universal Description Discovery and Integration (UDDI) registry
            used for Web service discovery.
          </div>
        </el-collapse-item>
        <el-collapse-item title="我要赞赏一下这个无私，淳朴，善良并且从不在网页加广告的站长买个好点的服务器" name="3">
          <div class="el-row">
            <div class="el-col el-col-6 el-col-offset-1">
              <div class="el-card is-always-shadow" style="width: 200px;">
                <div class="el-card__body" style="padding: 0px;">
                  <img src="../assets/wxpay.jpg" class="image"/>
                </div>
              </div>
            </div>
            <div class="el-col el-col-6 el-col-offset-1">
              <div class="el-card is-always-shadow" style="width: 200px;">
                <div class="el-card__body" style="padding: 0px;">
                  <img src="../assets/alipay.jpg" class="image"/>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

      <p style="margin-top: 20px; margin-bottom: 20px;">
        Copyright © 2023. UUID.YANGDA.XIN All Rights Reserved.
        <a href="mailto:1074156299@qq.com">Contact Us.</a>
        &nbsp;&nbsp;黔ICP备2023011802号
      </p>
    </el-footer>
  </el-container>
</template>

<script>
import ClipboardJS from 'clipboard';

export default {
  data() {
    return {
      activeNames: '3',

      uuUidOne: '',
      uuidList: [],

      uuUidOneCopyBtnText: 'Copy',
      uuUidListCopyBtnText: 'Copy',

      batchForm: {
        version: 'UUID4',
        checkbox: false,
        num: '10'
      },

      versionForm: {
        uuid: ''
      },

      batchRules: {
        num: [
          {validator: this.validateNum, trigger: 'blur'}
        ]
      },

      versionRules: {
        uuid: [
          {validator: this.validateUuid, trigger: 'blur'}
        ]
      }

    }
  },

  created() {
    this.generateOne()
  },

  methods: {
    validateNum(rule, value, callback) {
      if (value.trim() === '') {
        callback(new Error('NUM IS NOT NULL'))
        return
      }

      if (value < 1 || value > 3000) {
        callback(new Error('Num is 1-3000, Generate 10 UUID'))
      }

      callback()
    },

    validateUuid(rule, value, callback) {
      if (value.trim() === '') {
        callback(new Error('UUID IS NOT NULL'))
        return
      }

      if (value.length >= 100) {
        callback(new Error('UUID string length cannot be greater than 100'))
      }

      callback()
    },

    submitForm() {
      this.$refs['batchForm'].validate((valid) => {
        if (valid) {
          this.generateBatch()
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },

    submitCheckForm() {
      this.$refs['versionForm'].validate((valid) => {
        if (valid) {
          this.uuidVersion()
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },

    generateOne() {
      this.getRequest('/generateOne', {}).then(resp => {
        if (resp && resp.success) {
          this.uuUidOne = resp.data.uuid
        }
      })
    },

    generateBatch() {
      this.getRequest('/generateBatch', this.batchForm).then(resp => {
        if (resp && resp.success) {
          this.uuidList = resp.data.uuidList
        }
      })
    },

    uuidVersion() {
      this.getRequest('/uuidVersion', this.versionForm).then(resp => {
        if (resp && resp.success) {
          this.$alert('Current UUID Version Is ' + resp.data.version, {
            confirmButtonText: '我知道了',
          });
        }
      })
    },

    copyUuidOne() {
      let _that = this
      const clipboardOne = new ClipboardJS('.btn-copy-uuid-one', {
        text() {
          return _that.uuUidOne
        }
      })

      clipboardOne.on('success', () => {
        _that.uuUidOneCopyBtnText = 'Copied'
        clipboardOne.destroy()
      })

      clipboardOne.on('error', () => {
        console.log('复制失败')
      })
    },

    copyUuidList() {
      let _that = this
      if (_that.uuidList.length === 0) {
        return
      }

      let copyText = '';
      _that.uuidList.forEach(v => {
        copyText += v + '\n'
      })

      const clipboardList = new ClipboardJS('.btn-copy-uuid-list', {
        text() {
          return copyText
        }
      })

      clipboardList.on('success', () => {
        _that.uuUidListCopyBtnText = 'Copied'
        clipboardList.destroy()
      })

      clipboardList.on('error', () => {
        console.log('复制失败')
      })
    }

  }

}
</script>

<style lang="scss" scoped>
.el-header {
  background-color: #303133;
  color: #fff;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  font-weight: 700;
}

.el-main {
  background-color: #fff;
  color: #303133;
  font-family: PingFang SC, Microsoft YaHei, SimSun, Arial, sans-serif;
  margin: 0 auto;
  width: 85%;
  text-align: center;
  margin-top: 50px;


  .uuid4-style {
    font-size: 28px;
    text-align: center;
    vertical-align: middle;

    .el-button-group {
      display: inline-block;
      vertical-align: middle;

      .el-button--primary:first-child {
        border-right-color: hsla(0, 0%, 100%, .5);
      }

      .el-button:not(:last-child) {
        margin-right: -1px;
      }

      > .el-button--primary:last-child {
        border-left-color: hsla(0, 0%, 100%, .5);
      }

      > .el-button:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .el-checkbox {
    margin-left: 20px;
  }

  .form-style {
    margin-top: 20px;
  }
}

.el-footer {
  height: 60px;
  background-color: #fff;
  color: #333;
  margin: 0 auto;
  text-align: left;
  width: 85%;

  .image {
    width: 100%;
    display: block;
  }
}


</style>
