import Vue from 'vue'
import App from './App.vue'

//导入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

//引入vue-router插件
import VueRouter from "vue-router"
// 引入路由实例对象
import router from "@/router"


// 引入axios
import {getRequest} from '@/utils/request'
Vue.prototype.getRequest = getRequest;

Vue.use(ElementUI)
Vue.use(VueRouter)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
