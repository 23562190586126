//该文件专门用于创建整个应用的路由实例对象
import VueRouter from "vue-router";

//引入组件
import Index from "@/views/Index";

//创建并暴露一个路由实例对象
export default new VueRouter({
    routes: [
        {
            path: "/",
            component: Index,
        },
        {
            path: "/index",
            component: Index,
        }
    ],
});

