<template>
  <div id="app">
    <!-- 页面渲染 -->
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#app, .el-container, body, html {
  padding: 0;
  margin: 0;
}

body {
  min-width: 1400px;
}

.uuidVersion {
  .el-radio-button .el-radio-button__inner:hover {
    color: #606266 !important;
  }

  .el-radio-button.is-active .el-radio-button__inner:hover {
    color: #fff !important;
  }
}


.myCheckBox.is-checked {
  .el-checkbox__inner {
    background-color: #303133 !important;
    border-color: #303133 !important;
  }

  .el-checkbox__label {
    color: #303133 !important;
  }
}

.el-button-group, .el-message-box__wrapper {
  .el-button--primary, .el-button--primary:hover {
    color: #fff !important;
    background-color: #303133 !important;
    border-color: #303133 !important;
  }

  .el-button--primary:hover {
    opacity: .8 !important;
  }
}

</style>
