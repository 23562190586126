/**
 * 获取当前URL域名
 */
class MyTool {
    /**
     * 获取当前网站域名的域名
     * @returns {string}
     */
    getDomain() {
        // 获取当前网站的完整URL
        let url = window.location.href;
        // 通过正则表达式解析出域名
        let matches = url.match(/^https?:\/\/([^:\/]+)/i);
        return matches && matches[1];
    }
}

export const myTool = new MyTool();
