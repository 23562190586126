import axios from 'axios'
import {Message} from 'element-ui'
import router from '../router'
import {mymessage} from './mymessgae.js'
import config from '@/utils/config'
import {myTool} from '@/utils/tool'

axios.interceptors.response.use(success => {
    if (success.status && success.data.status === 500) {
        Message.error({message: success.data.msg})
        return;
    }
    return success.data;
}, error => {
    if (error.response.status === 504) {
        Message.error({message: '服务器内部错误'})
    } else if (error.response.status === 404) {
        Message.error({message: '路径不存在'})
    } else if (error.response.status === 403) {
        Message.error({message: '权限不足，请联系管理员'})
    } else if (error.response.status === 401) {
        mymessage.error({message: error.response.data.msg ? error.response.data.msg : '尚未登录，请登录'})
        router.replace('/');
    } else {
        if (error.response.data.msg) {
            Message.error({message: error.response.data.msg})
        } else {
            Message.error({message: '未知错误!'})
        }
    }
})

//可以设置基础访问地址
let domain = 'http://' + myTool.getDomain();
let base = config.product ? domain + '/api/toolUuid' : domain + ':7078/toolUuid'


export const postKeyValueRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params,
        transformRequest: [function (data) {
            let ret = '';
            for (let i in data) {
                ret += encodeURIComponent(i) + '=' + encodeURIComponent(data[i]) + '&'
            }
            return ret;
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}

export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: `${base}${url}`,
        data: params
    })
}

export const getRequest = (url, params) => {
    return axios({
        method: 'get',
        url: `${base}${url}`,
        params: params
    })
}

